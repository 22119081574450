import differenceInDays from 'date-fns/differenceInDays';

/**
 * Cart Order (archive images)
 */
// other=...
// MSS ~ 'PSS,MSS'
const orderAllowedInstruments = [
  'AVR',
  'BWD',
  'GTNL1',
  'IRS',
  'KMSS',
  'LISS3',
  'LISS4',
  'MSI',
  'MSS',
  'MSU101',
  'MSU102',
  'MSUMR',
  'MSUTM101,MSUTM102',
  'MSUTM101',
  'MSUTM102',
  'MUL12U-R',
  'MUX',
  'MUXNAD',
  'OLITIRS',
  'PANMUX',
  'PMS',
  'SSR',
  'WFI',
  'WFV',
  'MUL12U-R',
  // 'MSU101,MSU102',
];

const orderNotAllowedPlatforms = [
  //
  'BKA1',
];

export const canOrderImageByInstrument = (
  instrumentIdentifier: string,
  platformIdentifier: string
) => {
  if (
    orderAllowedInstruments.indexOf(instrumentIdentifier) > -1 &&
    orderNotAllowedPlatforms.indexOf(platformIdentifier) === -1
  ) {
    return true;
  } else {
    return false;
  }
};

// const canOrderByUserGroup = () => { ; canOrderOperativeImageByDate = ()
export const canOrderOperativeImageAndGetDiffByDate: (
  acquisitionDate: Date
) => [boolean, number] = acquisitionDate => {
  const acqDate = acquisitionDate;
  const nowDate = new Date();
  const diffDays = differenceInDays(nowDate, acqDate);

  // if (isFreeUser || diffDays > 30) {
  if (diffDays > 30) {
    return [true, diffDays];
  } else {
    return [false, diffDays];
  }
};
