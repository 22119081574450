
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import ButtonTabs from '@geobank/components/src/common/ButtonTabs/ButtonTabs'; // , { IActiveButtonTab }
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
import PlatformTree, { getPlatformIds } from 'components/searchForm/PlatformTree/PlatformTree';
import ResolutionCheckbox from 'components/searchForm/ResolutionCheckbox/ResolutionCheckbox';

import { t } from '@lingui/macro'; // Trans
import { I18n } from '@lingui/react';

import { resolutionToInstruments } from 'components/searchForm/resolutionMapping';
import { getIsFree } from 'ducks/authIAM';
import { getInstrumentsById } from 'ducks/classifier';
import { SourceType } from 'ducks/types/metadataTypes';

const styles = require('./PlatformFieldset-styles.module.scss');

interface PlatformFieldsetProps {
  onChange: (field: string, value: any) => void;
  // onChange: (data: any) => void;

  checked?: string[];
  source: SourceType;
}

const PlatformFieldset: React.FC<PlatformFieldsetProps> = props => {
  const { checked, onChange, source } = props;
  const [activeTab, setActiveTab] = useState('resolutions'); // Спутники platforms \ resolutions
  const isFreeUser = useSelector(getIsFree);
  const instrumentsData = useSelector(getInstrumentsById);

  const handleRegionSelectionChange = (value: string) => {
    const activeItem = value;
    setActiveTab(activeItem); // label
  };

  const handlePlatformChange = (data: any, changed: { name: string; value: boolean }) => {
    const { sensorTypeIds, platformTypeIds } = data;
    // if (sensorTypeIds.length > 0) {
    // props.onChange('instrument_identifiers', sensorTypeIds.join(',')); // sensor_ids
    if (source === 'survey') {
      const changedNameSplited = changed.name.split(':');
      // const instrId = changedNameSplited[0] === 'sensorType' ? changedNameSplited[1] : checked; // sensorTypeIds[0];
      // and value === true
      if (changedNameSplited[0] === 'sensorType') {
        const instrId = changedNameSplited[1];
        props.onChange('instrumentIdentifiers', [instrId]);
      } else {
        props.onChange('instrumentIdentifiers', [...checked!]);
      }
    } else {
      props.onChange('instrumentIdentifiers', sensorTypeIds);
      props.onChange('platformTypeIdentifier', platformTypeIds);
    }
    // }
  };

  const handleResolutionChange = useCallback(
    (resolutions: string[], changed?: { name: string; value: boolean }) => {
      let instruments: string[] = [];
      if (source === 'survey') {
        if (changed && changed.value === true) {
          // resolutions = [changed.name];
          // @ts-ignore
          instruments = [resolutionToInstruments[changed.name][0]];
          if (!isFreeUser) {
            instruments = instruments.filter(
              instr => !['AVR', 'MUL12U-R', 'MSUTM101,MSUTM102'].includes(instr)
            );
          }
        }
      } else {
        resolutions.forEach(item => {
          // @ts-ignore
          instruments = instruments.concat(resolutionToInstruments[item]);
          if (!isFreeUser) {
            instruments = instruments.filter(
              instr =>
                ![
                  'AVR',
                  'MUL12U-R',
                  'MSUTM101,MSUTM102',
                  'MUX',
                  'PANMUX',
                  'MUXNAD',
                  'WFV',
                  'PMS',
                  'BWD',
                  'IRS',
                  'WFI',
                  'LISS3',
                  'LISS4',
                ].includes(instr)
            );
          }
        });
      }
      onChange('instrumentIdentifiers', instruments);
      onChange('platformTypeIdentifier', getPlatformIds(instrumentsData, instruments));
    },
    [onChange, source, isFreeUser, instrumentsData]
  );

  // TODO - make Set()
  const resolutionsChecked: string[] = [];
  checked?.forEach(instrumentIdentifier => {
    Object.entries(resolutionToInstruments).forEach(([res, instrs]) => {
      // @ts-ignore
      if (instrs.indexOf(instrumentIdentifier) !== -1) {
        resolutionsChecked.push(res);
      }
    });
  });

  return (
    <>
      <div className={styles.tabsContainer}>
        <I18n>
          {({ i18n }) => (
            <ButtonTabs
              className={styles.buttonTabs}
              // Качество, Спутники
              tabsList={[
                { value: 'resolutions', label: i18n._(t`Разрешение`) }, // Пространственное разрешение
                { value: 'platforms', label: i18n._(t`Съёмочная аппаратура`) }, // Космические аппараты
              ]}
              activeTab={activeTab}
              onChange={handleRegionSelectionChange}
            />
          )}
        </I18n>
      </div>
      <div
        className={classNames(
          styles.bodyContainer,
          resolutionsChecked.length === 0 ? styles.bodyContainerInvalid : ''
        )}
      >
        {activeTab === 'platforms' ? (
          <CustomScrollBarContainer heightMin="80px" heightMax="144px" color="#CEEAFB">
            {/* TODO - single select */}
            <PlatformTree checked={props.checked} onChange={handlePlatformChange} source={source} />
          </CustomScrollBarContainer>
        ) : (
          // TODO - single select
          <ResolutionCheckbox checked={resolutionsChecked} onChange={handleResolutionChange} />
        )}
      </div>
    </>
  );
};

export default PlatformFieldset;
