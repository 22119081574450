import React from 'react';

import { Select, SelectOption } from '@geobank/components/src/forms/Select';
import { Trans } from '@lingui/macro';
import { notifyActions } from 'ducks/message';
import { getCurrentCartParams } from 'ducks/order';
import { useDispatch, useSelector } from 'react-redux';
import { ProductOptionType } from '../helpers/fieldTypes';

interface BytesPerPixelFieldProps {
  options: ProductOptionType[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const BytesPerPixelField: React.FC<BytesPerPixelFieldProps> = props => {
  const { options, onChange } = props;
  const dispatch = useDispatch();
  const currentCartParams = useSelector(getCurrentCartParams);

  // React.useEffect(() => {
  //   if (currentCartParams.bytesPerPixel === 1) {
  //     dispatch(notifyActions.push({
  //       message: (
  //         <Trans id="order_image.byte_per_pixel_hint">
  //           Продукт 1 байт не предназначен <br />для проведения радиометрических измерений
  //         </Trans>
  //       ),
  //       color: 'info',
  //       timeout: 4000,
  //     }))
  //   }
  // }, [dispatch, currentCartParams.bytesPerPixel])

  const handleBytePerPixelChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    if (String(value) === '1') {
      dispatch(
        notifyActions.push({
          message: (
            <Trans id="order_image.byte_per_pixel_hint">
              Продукт 1 байт не предназначен <br />
              для проведения радиометрических измерений
            </Trans>
          ),
          color: 'info',
          timeout: 4000,
        })
      );
    }
    onChange(event);
  };

  return (
    <Select
      name={'bytesPerPixel'}
      value={currentCartParams.bytesPerPixel}
      onChange={handleBytePerPixelChange}
    >
      {options.map(param => (
        <SelectOption key={param.value} value={param.value}>
          {param.title}
        </SelectOption>
      ))}
    </Select>
  );
};

export default React.memo(BytesPerPixelField);
