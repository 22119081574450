import { Trans } from '@lingui/macro';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

import Accordion from '@geobank/components/src/common/Accordion/Accordion';
import CheckBox from 'components/common/forms/CheckBox/CheckBox';
import HelpIcon from 'components/common/HelpIcon/HelpIcon';
import {
  getCurrentCartImages,
  getCurrentCartParams,
  getImageSourceName,
  updateCurrentCartParams,
} from 'ducks/order';
import { LicenseValue, UsageTimeValue } from 'locales/dicts/license';
import BytesPerPixelField from './fields/BytesPerPixelField';
import License from './fields/License';
import ProductCodeField from './fields/ProductCodeField';
import SrsNameField from './fields/SrsNameField';
import UsageTime from './fields/UsageTime';

import { filterOptions } from './helpers/fieldOptions';
import { getAvailableOptions } from './helpers/sourceMapping';

import TagsField from 'components/order/productParams/TagsField/TagsField';

import styles from './OutputProductParameters.module.scss';

export const getPlatformInstrumentFromEtrisId = (id: string) => {
  return `${id.split('.')[1]}.${id.split('.')[2]}`;
};

interface OutputProductParametersProps {
  isProductParamsOpen: boolean;
  onProductParamsToggle: () => void;
}

const OutputProductParameters: React.FC<OutputProductParametersProps> = props => {
  const { isProductParamsOpen, onProductParamsToggle } = props;
  const dispatch = useDispatch();
  const currentCartParams = useSelector(getCurrentCartParams);
  const imageSourceName = useSelector(getImageSourceName);
  const items = useSelector(getCurrentCartImages);

  const isL1Only: boolean =
    items.filter(item =>
      ['ZY302.MUX', 'GF6.WFV'].includes(getPlatformInstrumentFromEtrisId(item.metadataIdentifier))
    ).length > 0;
  const isL2Only: boolean =
    items.filter(item =>
      [
        'AI2D.AVR',
        'CB04.MUX',
        'CB04.PANMUX',
        'CB04.WFI',
        'CB04.IRS',
        'IRSR2A.LISS4',
        'GF6.PMS',
        'ZY3.BWD',
        'IRSR2A.LISS3',
        'SZ2M02.MUL12U-R',
        'SZ2M04.MUL12U-R',
        'SZ2M06.MUL12U-R',
      ].includes(getPlatformInstrumentFromEtrisId(item.metadataIdentifier))
    ).length > 0;

  const imageSourceOptions = isL2Only ? 'bbp' : isL1Only ? 'l1Only' : imageSourceName || 'etris';
  const avOptions = getAvailableOptions(imageSourceOptions, currentCartParams);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const fieldName = event.target.name;
    const value = event.target.value;
    dispatch(updateCurrentCartParams.request({ [fieldName]: value }));
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    const params = { ...currentCartParams, productCode: value };
    const availableOptions = getAvailableOptions(
      imageSourceName ? imageSourceName : 'etris',
      params
    );

    if (availableOptions.srsName.indexOf(params.srsName) === -1) {
      params.srsName = availableOptions.srsName[0];
    }
    if (availableOptions.bytesPerPixel.indexOf(params.bytesPerPixel) === -1) {
      params.bytesPerPixel = availableOptions.bytesPerPixel[0];
    }
    if (availableOptions.elevation.indexOf(params.elevation) === -1) {
      params.elevation = availableOptions.elevation[0];
    }

    dispatch(updateCurrentCartParams.request(params));
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    const checked = event.target.checked;
    dispatch(updateCurrentCartParams.request({ [name]: checked }));
  };

  const handleLicenseChange = (value: LicenseValue) => {
    dispatch(updateCurrentCartParams.request({ license: value }));
  };

  const handleUsageTimeChange = (value: UsageTimeValue) => {
    dispatch(updateCurrentCartParams.request({ usageTime: value }));
  };

  const handleTagsChange = (tags: string[]) => {
    dispatch(updateCurrentCartParams.request({ tags }));
  };

  return (
    <div className={styles.root}>
      <Accordion
        label={
          <span className={styles.paramsHeader}>
            <Trans>Параметры выдачи продукции</Trans>
          </span>
        }
        isOpen={isProductParamsOpen}
        onToggle={onProductParamsToggle}
        right={true}
      >
        <TagsField tags={currentCartParams.tags} onChange={handleTagsChange} />
        {/* --- */}
        <div className={styles.paramTitle}>
          <span>
            <Trans>Информационный продукт</Trans>
            <Tooltip
              tagName="span"
              styles={{ marginLeft: '6px' }}
              tipContentClassName="tip-content--border-grey tip-content--infoproduct"
              arrow={false}
              background="white"
              color="black"
              direction="bottom"
              content={
                <Trans>
                  L1 - Снимок с координатной привязкой RPC, не трансформированный в картографическую
                  проекцию <br />
                  L2 - Снимок с координатной привязкой RPC, трансформированный в картографическую
                  проекцию
                </Trans>
              }
            >
              <HelpIcon />
            </Tooltip>
          </span>
        </div>
        <div className={styles.paramField}>
          <ProductCodeField
            name="productCode"
            value={currentCartParams.productCode}
            options={filterOptions('productCode', avOptions.productCode)}
            onChange={handleProductChange}
          />
        </div>
        <div className={styles.paramContainer}>
          <div className={styles.width_50}>
            <div className={styles.paramTitle}>
              <span>
                <Trans>Система координат (проекция)</Trans>
              </span>
            </div>
          </div>
          <div className={styles.width_50}>
            <div className={styles.paramTitle}>
              <span>
                <Trans>Байт / пикс. кан.</Trans>
              </span>
              <Tooltip
                tagName="span"
                styles={{ marginLeft: '6px' }}
                tipContentClassName="tip-content--border-grey tip-content--infoproduct"
                arrow={false}
                background="white"
                color="black"
                direction="bottom"
                content={
                  <Trans id="order_image.byte_per_pixel_hint">
                    Продукт 1 байт не предназначен <br />
                    для проведения радиометрических измерений
                  </Trans>
                }
              >
                <HelpIcon />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.paramContainer}>
          <div className={styles.width_50}>
            <div className={styles.paramField}>
              <SrsNameField
                name="srsName"
                value={currentCartParams.srsName}
                options={filterOptions('srsName', avOptions.srsName)}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.width_50}>
            <div className={styles.paramField}>
              <BytesPerPixelField
                options={filterOptions('bytesPerPixel', avOptions.bytesPerPixel)}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.paramField}>
            <CheckBox
              // name="elevation"
              value="elevation"
              checked={currentCartParams.elevation}
              disabled={avOptions.elevation.length === 1}
              onChange={handleCheckBoxChange}
            >
              <Trans id="order_image.value_elevation">Использовать ЦМР</Trans>
              <Tooltip
                tagName="span"
                styles={{ marginLeft: '4px' }}
                tipContentClassName="tip-content--border-grey"
                arrow={false}
                background="white"
                color="black"
                direction="up"
                content={
                  <Trans id="order_image.hint_elevation">Применять цифровую модель рельефа</Trans>
                }
              >
                <HelpIcon />
              </Tooltip>
            </CheckBox>
          </div>
          <div style={{ fontSize: '14px' }}>
            <License
              style={{ marginBottom: '8px' }}
              active={currentCartParams.license}
              onSelect={handleLicenseChange}
            />

            <UsageTime active={currentCartParams.usageTime} onSelect={handleUsageTimeChange} />
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default OutputProductParameters;
