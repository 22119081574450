import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans } from '@lingui/macro'; // t
import dateFnsFormat from 'date-fns/format';

import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';

import { getIsGeofenced } from 'ducks/authIAM';
import {
  // balloonToggle,
  drawControl,
  getGeofenceGeometry,
  // getIsDrawControlShow,
  getRegionsOfInterest,
  setShapeGeometry,
} from 'ducks/map'; // getIsMainPanelShow
import { notifyActions } from 'ducks/message';
import {
  // getImageSourceType,
  getIsFetching,
  getSearchParams,
  // pickMosaicScenes,
  searchImages,
} from 'ducks/metadata';
import { createSimpleRequest, Request } from 'ducks/simpleRequest';
import { SearchParams } from 'ducks/types/metadataTypes';

import Loader from '@geobank/components/src/common/Loader/Loader';
import { selectStyle } from 'components/map/Map/featureStyles';
import GeoportalMap, { LAYER_GROUPS } from 'components/map/Map/Map';
import {
  formatArea,
  // geoJSONToWKTAndTransform
} from 'components/map/Map/utils';
import { genId } from 'components/utils/identifier';
import { validateFormValues } from 'components/utils/validation/form';

import GeoJSON from 'ol/format/GeoJSON';
import WKT from 'ol/format/WKT';
import { fromExtent } from 'ol/geom/Polygon';
import { Vector as VectorSource } from 'ol/source';

import * as turf from '@turf/turf';
import { validationFunctions } from './formValidation';
// import MosaicQuickOrder from './MosaicQuickOrder/MosaicQuickOrder';
import RequestProductPanel from './RequestProductPanel/RequestProductPanel';
import SearchForm from './SearchForm/SearchForm';

// import { updateCurrentCartParams } from 'ducks/order';
import styles from './LeftMenu.module.scss';

const SearchFormPanel: React.FC = () => {
  const dispatch = useDispatch();
  const [isSurveyPanelOpen, setIsSurveyPanelOpen] = React.useState<boolean>(false);
  // const [isMosaicQuickOrderShow, setIsMosaicQuickOrderShow] = React.useState<boolean>(false);

  const roi = useSelector(getRegionsOfInterest);
  const geofence = useSelector(getGeofenceGeometry);
  const searchParams = useSelector(getSearchParams);
  // const isFreeUser = useSelector((state: RootState) => getIsFree(state));
  // const requestType = useSelector((state: RootState) => getImageSourceType(state));
  const isFetching = useSelector(getIsFetching);
  const isGeofencedUser = useSelector(getIsGeofenced);

  const setRegionOfInterest = React.useCallback((geometry: string) => {
    const feature = new WKT().readFeature(geometry);
    const geoJSON = new GeoJSON().writeFeatureObject(feature);
    dispatch(drawControl.setGeometry(geoJSON));
    // dispatch(balloonToggle(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drawScreenFeature = (geometry: string) => {
    const map = GeoportalMap.getInstance();
    const feature = new WKT().readFeature(geometry);
    feature.setId(genId());
    feature.set('_type', 'screen');
    const { output, measure } = formatArea(feature.getGeometry());
    feature.set('area', output);
    feature.set('measure', measure);
    feature.setStyle(selectStyle);
    const vectorSource = new VectorSource({
      features: [feature],
    });
    const layer = map.getLayerById('regionLayer', LAYER_GROUPS.userLayers);
    layer.setSource(vectorSource);
  };

  React.useEffect(() => {
    if (!isGeofencedUser && searchParams.regionSelection === 'screen') {
      const map = GeoportalMap.getInstance();
      const extent = map.getOlMap().getView().calculateExtent();
      const polygon = fromExtent(extent);
      const screenGeometry = new WKT().writeGeometry(polygon, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });
      setRegionOfInterest(screenGeometry);
      drawScreenFeature(screenGeometry);
    }
  }, [isGeofencedUser, searchParams.regionSelection, setRegionOfInterest]);

  const getRegionGeometry = () => {
    let geometry;
    let polygon;
    const wktFormat = new WKT();
    const geojsonFormat = new GeoJSON();

    if (searchParams.regionSelection === 'screen') {
      const map = GeoportalMap.getInstance();
      const extent = map.getOlMap().getView().calculateExtent();
      polygon = fromExtent(extent);
      if (!isGeofencedUser) {
        drawScreenFeature(wktFormat.writeGeometry(polygon));
      } else {
        const search = geojsonFormat.writeGeometry(polygon.transform('EPSG:3857', 'EPSG:4326'));
        const intersection = turf.intersect(
          turf.polygon(JSON.parse(search).coordinates),
          turf.polygon(
            geofence[0].geometry.type === 'Polygon' ? geofence[0].geometry.coordinates : []
          )
        );
        if (intersection) {
          dispatch(setShapeGeometry(intersection, false));
          polygon = geojsonFormat
            .readFeature(intersection, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            })
            .getGeometry()!;
        }
      }
      geometry =
        searchParams.sourceType === 'archive'
          ? wktFormat.writeGeometry(polygon.transform('EPSG:3857', 'EPSG:4326'))
          : geojsonFormat.writeGeometry(polygon.transform('EPSG:3857', 'EPSG:4326'));
    } else {
      if (roi.length === 0) {
        return;
      }
      polygon = geojsonFormat.readFeature(roi[0]).getGeometry()!;
      // @ts-ignore
      geometry =
        searchParams.sourceType === 'archive'
          ? wktFormat.writeGeometry(polygon)
          : geojsonFormat.writeGeometry(polygon);
    }
    return geometry;
  };

  const handleShootingRequest = () => {
    // TODO - validate form
    const params: SearchParams = searchParams;
    const errors = validateFormValues(params, validationFunctions);
    if (Object.keys(errors).length === 0) {
      setIsSurveyPanelOpen(true);
    } else {
      dispatch(
        notifyActions.push({
          message: (
            <span>
              <Trans>Проверьте параметры заказа:</Trans>
              {Object.values<any[]>(errors).map((err, index) => (
                <React.Fragment key={index}>
                  <br /> - <Trans id={err[0]} />
                </React.Fragment>
              ))}
            </span>
          ),
          color: 'alert',
          timeout: 4000,
        })
      );
    }
  };

  const handleSubmitRequest = (regionsData: any[]) => {
    const params: SearchParams = { ...searchParams };
    const requestData: Request = {
      name: `Заявка от ${dateFnsFormat(new Date(), 'dd.MM.yyyy')}`,
      regions: regionsData.map(rr => ({
        // TODO - для PSS/MSS делать бандл (, т.е. добавлять второе СУ)
        ...rr,
        name: 'Район-1',
        beginShooting: dateFnsFormat(params.acquisitionDateAfter!, 'yyyy-MM-dd'),
        endShooting: params.acquisitionDateBefore
          ? dateFnsFormat(params.acquisitionDateBefore, 'yyyy-MM-dd')
          : null,
        polygon: getRegionGeometry(),
      })),
    };
    dispatch(createSimpleRequest.request(requestData));
    setIsSurveyPanelOpen(true);
  };

  const handleCancelRequest = () => {
    setIsSurveyPanelOpen(false);
  };

  const handleSearchRequest = () => {
    const params: SearchParams = { ...searchParams };
    if (params.invalidParams!.length > 0) {
      dispatch(
        notifyActions.push({
          message: (
            <span>
              <Trans>Интервал дат съёмки задан некорректно</Trans>
            </span>
          ),
          color: 'alert',
          timeout: 4000,
        })
      );
    } else if (params.instrumentIdentifiers?.length === 0) {
      dispatch(
        notifyActions.push({
          message: (
            <span>
              <Trans>Не выбрано разрешение съёмки или съёмочная аппаратура</Trans>
            </span>
          ),
          color: 'alert',
          timeout: 4000,
        })
      );
    } else {
      const geometry = getRegionGeometry();
      if (geometry) {
        // @ts-ignore
        params[`geometry`] = geometry;
        setRegionOfInterest(geometry);
        // if (params.sourceType === 'esmdp2') {
        //   dispatch(updateCurrentCartParams.request({ croppingPolygon: geometry }));
        // }
        dispatch(searchImages.request(params));
      } else {
        dispatch(
          notifyActions.push({
            message: (
              <span>
                <Trans>Не указан район интереса</Trans>
              </span>
            ),
            color: 'alert',
            timeout: 4000,
          })
        );
      }
    }
  };

  const handleClearSearchParams = () => {
    dispatch(searchImages.clearParams());
  };

  // const handleMosaicQuickOrder = () => {
  //   setIsMosaicQuickOrderShow(!isMosaicQuickOrderShow);
  // };

  // const handlePickMosaicScenes = (seasonedParams: IAllowSeasonParams) => {
  //   const params: SearchParams = { ...searchParams };
  //   const geometry = getRegionGeometry();
  //   if (geometry) {
  //     // @ts-ignore
  //     params[`geometry`] = geometry;
  //   }
  //   dispatch(pickMosaicScenes.request(params, seasonedParams));
  // };

  // const handleCloseMosaicQuickOrder = () => {
  //   setIsMosaicQuickOrderShow(false);
  // };
  return (
    <>
      {searchParams.sourceType === 'survey' &&
        isSurveyPanelOpen &&
        searchParams.instrumentIdentifiers && (
          <RequestProductPanel
            orderInstruments={searchParams.instrumentIdentifiers}
            onSubmit={handleSubmitRequest}
            onCancel={handleCancelRequest}
            // TODO не забыть прокинуть тут значение цены
          />
        )}
      {isSurveyPanelOpen && <div className={styles.searchPanelMask} />}
      <div className={styles.scrollable}>
        <CustomScrollBarContainer
          heightMax={'calc(100vh - 164px)'}
          thumbWidth={'5px'}
          color={'rgb(90, 184, 241)'}
        >
          <SearchForm />
        </CustomScrollBarContainer>
      </div>

      <div className={styles.div_search_option__search}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <Button color={ButtonColor.WHITE} width="120px" onClick={handleClearSearchParams}>
            <Trans>Сбросить параметры</Trans>
          </Button>
          {/* {requestType === 'esmdp2' && isFreeUser && (
            <Button width="120px" onClick={handleMosaicQuickOrder}>
              <Trans>Быстрый заказ мозаики</Trans>
            </Button>
          )} */}
          {isFetching ? (
            <div style={{ padding: '4px 44px 0 46px' }}>
              <Loader />
            </div>
          ) : ['archive', 'esmdp2'].includes(searchParams.sourceType!) ? (
            <Button width="120px" onClick={handleSearchRequest}>
              <Trans>
                Найти
                <br />
                снимки
              </Trans>
            </Button>
          ) : (
            <Button width="120px" onClick={handleShootingRequest}>
              <Trans>Заказать съёмку</Trans>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchFormPanel;
