import { Trans } from '@lingui/macro';
import { BASE_URL, S3_URL } from 'api/realAPI';

import React from 'react';

const styles = require('./modalContents-styles.module.scss');

export type ModalType = 'about' | 'documents' | 'contacts' | 'payment' | 'samples' | null;

export const getModalContent = (modalName: ModalType) => {
  let content = null;
  switch (modalName) {
    case 'about':
      content = (
        <div>
          <h3>
            <Trans>О портале</Trans>
          </h3>
          <div>
            <p>
              <Trans>
                Геопортал Роскосмоса – ресурс единой территориально-распределённой информационной
                системы дистанционного зондирования Земли из космоса (ЕТРИС ДЗЗ).
              </Trans>
            </p>
            <p>
              <Trans>
                Геопортал Роскосмоса - ресурс, который сочетает в себе средство просмотра
                космических снимков земной поверхности и средство поиска/заказа данных из
                Федерального фонда данных ДЗЗ. Отдельным группам пользователей также предоставляется
                сервис заказа проведения космической съёмки российскими аппаратами ДЗЗ.
              </Trans>
            </p>
            <a href="https://niitp.ru/" target="_blank" rel="noopener noreferrer">
              <Trans>Разработчик - АО "НИИ ТП"</Trans>
            </a>
          </div>
        </div>
      );
      break;
    case 'documents':
      content = (
        <div>
          <h3>Документы</h3>
          <div>
            <ul>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Договор-оферта-АО-РКС-Фонд.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Договор-оферта
                </a>
                ,&nbsp;
                <a
                  href={`${S3_URL}/geoportal-public/Offer_Agreement_for_Rendering_Services.PDF`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Offer Agreement (English)
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Согласие-на-обработку-персональных-данных.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Согласие на обработку персональных данных
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Политика-ПДн.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Политика в отношении обработки персональных данных
                </a>
              </li>
              {/* --- */}
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Закон о космической деятельности.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Закон о космической деятельности
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Федеральный закон от 22.04.2024 г. № 89-ФЗ.PDF`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Изменения в закон о космической деятельности
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Заявка_на_предоставление_данных__копий_данных__ДЗЗ_из_Фонда.docx`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Заявка на предоставление данных копий данных ДЗЗ из Фонда
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/ПП РФ от 24.08.2019 г. № 1086 правила создания и ведения ФФД ДЗЗ.PDF`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ПП РФ от 24.08.2019 г. № 1086 правила создания и ведения ФФД ДЗЗ
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/ПП РФ от 24.08.2019 г. № 1087 порядок и особенности предоставления данны....pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ПП РФ от 24.08.2019 г. № 1087 порядок и особенности предоставления данных
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/ПП РФ от 24.08.2019 г. № 1088 взаимодействие ФФД ДЗЗ с другими государст....pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ПП РФ от 24.08.2019 г. № 1088 взаимодействие ФФД ДЗЗ с другими государственными
                  фондами
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/ПП РФ от 26.10.2019 г. № 1377 взаимодействие ФФД ДЗЗ и ФФПД.PDF`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ПП РФ от 26.10.2019 г. № 1377 взаимодействие ФФД ДЗЗ и ФФПД
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/ПП РФ от 29.06.2019 г. № 840 Об утв. правил определения размера платы за....pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ПП РФ от 29.06.2019 г. № 840 Об утв. правил определения размера платы за
                  предоставление данных ДЗЗ
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/ПП РФ от 31.05.2019 г. № 689 Об определении оператора ФФД ДЗЗ.PDF`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ПП РФ от 31.05.2019 г. № 689 Об определении оператора ФФД ДЗЗ
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/ПП от 28 ноября 2024 г. N 1648.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ПП РФ от 28.11.2024 г. № 1648 о предоставлении данных ДЗЗ в 2025 году
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Приказ Об утверждении порядка предоставления данных ДЗЗ из Фонда.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Приказ Об утверждении порядка предоставления данных ДЗЗ из Фонда
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Приказ об утверждении регламента информационного взаимодействия фондов.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Приказ об утверждении регламента информационного взаимодействия фондов
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Приказ от 07.06.2019 № 173 требования к форме и содержанию.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Приказ от 07.06.2019 № 173 требования к форме и содержанию
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Приказ № 411 от 03.12.2024 ГК РОСКОСМОС.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Приказ от 03.12.2024 № 411 ГК РОСКОСМОС об установлении порядка предоставления
                  данных ДЗЗ из космоса
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/user-guide/v0.1/index.html#%D0%BE%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5-%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D1%83%D1%80%D1%8B-%D1%84%D0%B0%D0%B9%D0%BB%D0%B0-%D1%81-%D0%BC%D0%B5%D1%82%D0%B0%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D0%BC%D0%B8-%D0%BE%D0%B1-%D1%8D%D0%BA%D0%B7%D0%B5%D0%BC%D0%BF%D0%BB%D1%8F%D1%80%D0%B5-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85-%D0%B4%D0%B7%D0%B7-%D1%85%D1%80%D0%B0%D0%BD%D1%8F%D1%89%D0%B5%D0%BC%D1%81%D1%8F-%D0%B2-%D1%84%D0%BE%D0%BD%D0%B4%D0%B5`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Описание структуры файлов метаданных
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/Калькулятор_стоимости_архивных_данных_ДЗЗ.xlsx`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Калькулятор стоимости данных ДЗЗ
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
      break;
    case 'samples':
      content = (
        <div style={{ minWidth: '430px' }}>
          <h3>Образцы продуктов на основе данных с КА:</h3>
          <div style={{ marginLeft: '10px' }}>
            <h4>"Канопус-В"</h4>
          </div>
          <div>
            <ul style={{ marginTop: '-15px' }}>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/20220726-1068/ETRIS.KV6.MSS.3723.2.0.2019-08-29.L0.FKL_KLG.NTSOMZ_MSK/KV6_03723_02652_00_KANOPUS_20190829_051616_051626.SCN1.PMS.L2.tif`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Канопус-В L2 паншарп-изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/20220726-1068/ETRIS.KV6.MSS.3723.2.0.2019-08-29.L0.FKL_KLG.NTSOMZ_MSK/metadata_ETRIS.KV6.MSS.3723.2.0.2019-08-29.L0.FKL_KLG.NTSOMZ_MSK.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Канопус-В L2 метаданные паншарп-изображения
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/20220726-1069/ETRIS.KV6.MSS.3723.2.0.2019-08-29.L0.FKL_KLG.NTSOMZ_MSK/KV6_03723_02652_00_KANOPUS_20190829_051616_051626.SCN1.MS.L2.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Канопус-В L2 мультиспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/20220726-1069/ETRIS.KV6.MSS.3723.2.0.2019-08-29.L0.FKL_KLG.NTSOMZ_MSK/KV6_03723_02652_00_KANOPUS_20190829_051616_051626.SCN1.PAN.L2.tif`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Канопус-В L2 панхроматическое изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/20220726-1069/ETRIS.KV6.MSS.3723.2.0.2019-08-29.L0.FKL_KLG.NTSOMZ_MSK/metadata_ETRIS.KV6.MSS.3723.2.0.2019-08-29.L0.FKL_KLG.NTSOMZ_MSK.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Канопус-В L2 метаданные
                  </a>
                </li>
              </div>
            </ul>
          </div>
          <div style={{ marginLeft: '10px' }}>
            <h4>"Ресурс-П"</h4>
          </div>
          <ul style={{ marginTop: '-15px' }}>
            <div className={styles.sampleMargin}>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/product-samples/20220726-1070/ETRIS.RP1.GTNL1.4753.3.0.2019-08-31.L0.NTSOMZ_MSK.NTSOMZ_MSK/RP1_34586_03_GEOTON_20190831_051209_051227.SCN1.PMS.L2.tif`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ресурс-П L2 паншарп-изображение
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/product-samples/20220726-1070/ETRIS.RP1.GTNL1.4753.3.0.2019-08-31.L0.NTSOMZ_MSK.NTSOMZ_MSK/metadata_ETRIS.RP1.GTNL1.4753.3.0.2019-08-31.L0.NTSOMZ_MSK.NTSOMZ_MSK.zip`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ресурс-П L2 метаданные
                </a>
              </li>
            </div>
            <div className={styles.sampleMargin}>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/product-samples/20220726-1071/ETRIS.RP1.GTNL1.4753.3.0.2019-08-31.L0.NTSOMZ_MSK.NTSOMZ_MSK/RP1_34586_03_GEOTON_20190831_051209_051227.SCN1.MS.L2.tif`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ресурс-П L2 мультиспектральное изображение
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/product-samples/20220726-1071/ETRIS.RP1.GTNL1.4753.3.0.2019-08-31.L0.NTSOMZ_MSK.NTSOMZ_MSK/RP1_34586_03_GEOTON_20190831_051209_051227.SCN1.PAN.L2.tif`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ресурс-П L2 панхроматическое изображение
                </a>
              </li>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/product-samples/20220726-1071/ETRIS.RP1.GTNL1.4753.3.0.2019-08-31.L0.NTSOMZ_MSK.NTSOMZ_MSK/metadata_ETRIS.RP1.GTNL1.4753.3.0.2019-08-31.L0.NTSOMZ_MSK.NTSOMZ_MSK.zip`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ресурс-П L2 метаданные
                </a>
              </li>
            </div>
            <div className={styles.sampleMargin}>
              <li>
                <a
                  href={`${S3_URL}/geoportal-public/product-samples/GSA_standart_product.zip`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ресурс-П (ГСА) L2
                </a>
              </li>
            </div>
            {/* <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/01016_03/fr_0041_0306_40828_1_01016_03_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №1 (ГСА) маршрут 01016_03 с площадью 897 км<sup>2</sup>
                    <br />
                    (Астраханская область) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/01016_03/fr_0041_0306_40828_1_01016_03_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №1 (ГСА) маршрут 01016_03 с площадью 897 км<sup>2</sup>
                    <br />
                    (Астраханская область) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/02716_06/fr_0042_0306_12685_1_02716_06_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №2 (ГСА) маршрут 02716_06 с площадью 961 км<sup>2</sup>
                    <br />
                    (Крымский пролив) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/02716_06/fr_0042_0306_12685_1_02716_06_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №2 (ГСА) маршрут 02716_06 с площадью 961 км<sup>2</sup>
                    <br />
                    (Крымский пролив) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/02731_07/fr_0042_0306_12699_1_02731_07_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №2 (ГСА) маршрут 02731_07 с площадью 936 км<sup>2</sup>
                    <br />
                    (Ставропольский край) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/02731_07/fr_0042_0306_12699_1_02731_07_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №2 (ГСА) маршрут 02731_07 с площадью 936 км<sup>2</sup>
                    <br />
                    (Ставропольский край) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/02783_04/fr_0043_0102_02784_1_02783_04_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №3 (ГСА) маршрут 02783_04 с площадью 1009 км<sup>2</sup>
                    <br />
                    (Республика Крым) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/02783_04/fr_0043_0102_02784_1_02783_04_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №3 (ГСА) маршрут 02783_04 с площадью 1009 км<sup>2</sup>
                    <br />
                    (Республика Крым) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/03893_05/fr_0042_0303_13863_1_03893_05_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №2 (ГСА) маршрут 03893_05 с площадью 872 км<sup>2</sup>
                    <br />
                    (Новосибирская область) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/03893_05/fr_0042_0303_13863_1_03893_05_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №2 (ГСА) маршрут 03893_05 с площадью 872 км<sup>2</sup>
                    <br />
                    (Новосибирская область) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/04679_06/fr_0043_0306_04688_1_04679_06_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №3 (ГСА) маршрут 04679_06 с площадью 837 км<sup>2</sup>
                    <br />
                    (Ситуэ, Мьянма) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/04679_06/fr_0043_0306_04688_1_04679_06_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №3 (ГСА) маршрут 04679_06 с площадью 837 км<sup>2</sup>
                    <br />
                    (Ситуэ, Мьянма) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/05095_05/fr_0043_0102_05101_1_05095_05_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №3 (ГСА) маршрут 05095_05 с площадью 668 км<sup>2</sup>
                    <br />
                    (Керченский пролив) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/05095_05/fr_0043_0102_05101_1_05095_05_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №3 (ГСА) маршрут 05095_05 с площадью 668 км<sup>2</sup>
                    <br />
                    (Керченский пролив) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/05216_02/fr_0041_0102_05263_1_05216_02_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №1 (ГСА) маршрут 05216_02 с площадью 953 км<sup>2</sup>
                    <br />
                    (Лос-Анджелес, США) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/05216_02/fr_0041_0102_05263_1_05216_02_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №1 (ГСА) маршрут 05216_02 с площадью 953 км<sup>2</sup>
                    <br />
                    (Лос-Анджелес, США) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/07174_06/fr_0041_0102_17136_1_07174_06_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №1 (ГСА) маршрут 07174_06 с площадью 922 км<sup>2</sup>
                    <br />
                    (Ростов-на-Дону) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/07174_06/fr_0041_0102_17136_1_07174_06_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №1 (ГСА) маршрут 07174_06 с площадью 922 км<sup>2</sup>
                    <br />
                    (Ростов-на-Дону) метаданные
                  </a>
                </li>
              </div>
              <div className={styles.sampleMargin}>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/08608_02/fr_0041_0102_28497_1_08608_02_L2B.tiff`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №1 (ГСА) маршрут 08608_02 с площадью 1236 км<sup>2</sup>
                    <br />
                    (Иркутская область) гиперспектральное изображение
                  </a>
                </li>
                <li>
                  <a
                    href={`${S3_URL}/geoportal-public/product-samples/08608_02/fr_0041_0102_28497_1_08608_02_L2B.zip`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ресурс-П №1 (ГСА) маршрут 08608_02 с площадью 1236 км<sup>2</sup>
                    <br />
                    (Иркутская область) метаданные
                  </a>
                </li>
              </div> */}
          </ul>
        </div>
      );
      break;
    case 'contacts':
      content = (
        <div>
          <h3>Контакты</h3>
          <div>
            <p>
              Научный центр оперативного мониторинга Земли
              <br />
              АО "Российские космические системы"
              <br />
              Отдел взаимодействия с потребителями
              <br />
              8-495-229-43-89
              <br />
              8-495-280-72-25
            </p>
            <p>
              Фактический адрес: 127490, Москва, ул. Декабристов, владение 51, строение 25
              <br />
              Юридический адрес: 111250, Москва, ул. Авиамоторная, д. 53, строение 1<br />
              Электронная почта: <a href="mailto:fddzz@ntsomz.ru">fddzz@ntsomz.ru</a>
            </p>
            <p>
              Банковские реквизиты: <br />
              Банк ВТБ (ПАО) г. Москва <br />
              БИК 044525187
              <br />
              Расчетный счет 40702810000030005365
              <br />
              ИНН/КПП: 7722698789/774550001
              <br />
              ОГРН: 1097746649681
            </p>
          </div>
        </div>
      );
      break;
    case 'payment':
      content = (
        <div>
          <h3>Способы оплаты</h3>
          <div>
            <p>
              <b>Наличный расчёт</b>
              <br />
              не предоставляется.
            </p>

            <p>
              <b>Банковской картой</b> <br />
              Для выбора оплаты товара с помощью банковской карты корзине с отобранными снимками
              необходимо нажать кнопку "Оформить заказ". Оплата происходит через ПАО СБЕРБАНК с
              использованием банковских карт следующих платёжных систем:
            </p>
            <ul>
              <li>
                МИР{' '}
                <img
                  src={`${BASE_URL}/images/payment/mir.png`}
                  className={styles.paymentImg}
                  alt="mir"
                />
              </li>
              <li>
                VISA International{' '}
                <img
                  src={`${BASE_URL}/images/payment/visa.png`}
                  className={styles.paymentImg}
                  alt="visa"
                />
              </li>
              <li>
                Mastercard Worldwide{' '}
                <img
                  src={`${BASE_URL}/images/payment/mastercard.png`}
                  className={styles.paymentImg}
                  alt="mastercard"
                />
              </li>
              <li>
                JCB{' '}
                <img
                  src={`${BASE_URL}/images/payment/jcb.png`}
                  className={styles.paymentImg}
                  alt="jsb"
                />
              </li>
            </ul>
            <p>
              Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз
              ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в
              защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк
              поддерживает технологию безопасного проведения интернет-платежей Verified By Visa,
              MasterCard SecureCode, MIR Accept, J-Secure, для проведения платежа также может
              потребоваться ввод специального пароля.
            </p>
            <p>
              Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой
              персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет
              предоставлена третьим лицам за исключением случаев, предусмотренных законодательством
              РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с
              требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.
            </p>

            <p>
              <b>Возврат товара</b> <br />
              Рассмотрение претензий по оказанным услугам, сроки и возврат денежных средств или
              замена данных ДЗЗ возможны при получении соответствующего обращения на адрес
              электронной почты <a href="mailto:fddzz@ntsomz.ru">fddzz@ntsomz.ru</a> и проведения
              мероприятий в соответствии с блок-схемой претензионной работы, выполняемой в рамках
              эксплуатации федерального фонда данных ДЗЗ согласно приложению к договору-оферте.
            </p>
          </div>
        </div>
      );
      break;
  }
  return content;
};
