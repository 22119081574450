import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';

import { addMonths, isValid } from 'date-fns';

import DateInput from '@geobank/components/src/forms/DateInput/DateInput';

import { changeSearchParams, getSearchParams } from 'ducks/metadata';

import { getLanguage } from 'ducks/locale';
import styles from './DateRangeParam.module.scss';

export const isValidDate = (date?: Date) => {
  return isValid(date);
};

const DateRangeParam: React.FC = () => {
  const dispatch = useDispatch();
  const searchParams = useSelector(getSearchParams);
  const locale = useSelector(getLanguage);
  const [invalidAcquisitionDateAfter, setInvalidAcquisitionDateAfter] = React.useState<boolean>(
    false
  );
  const [invalidAcquisitionDateBefore, setInvalidAcquisitionDateBefore] = React.useState<boolean>(
    false
  );

  const handleDateStartChange = (value?: Date) => {
    if (value === undefined || value !== searchParams.acquisitionDateAfter) {
      dispatch(changeSearchParams({ acquisitionDateAfter: value }));
    }
  };

  const handleDateEndChange = (value?: Date) => {
    if (value !== searchParams.acquisitionDateBefore) {
      dispatch(changeSearchParams({ acquisitionDateBefore: value }));
    }
  };

  React.useEffect(() => {
    let updatedInvalidParams: string[] = [];
    if (!invalidAcquisitionDateAfter) {
      updatedInvalidParams = searchParams.invalidParams!.filter(
        param => param !== 'acquisitionDateAfter'
      );
    } else {
      updatedInvalidParams = searchParams.invalidParams!.concat(['acquisitionDateAfter']);
    }
    dispatch(changeSearchParams({ invalidParams: updatedInvalidParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invalidAcquisitionDateAfter]);

  React.useEffect(() => {
    let updatedInvalidParams: string[] = [];
    if (!invalidAcquisitionDateBefore) {
      updatedInvalidParams = searchParams.invalidParams!.filter(
        param => param !== 'acquisitionDateBefore'
      );
    } else {
      updatedInvalidParams = searchParams.invalidParams!.concat(['acquisitionDateBefore']);
    }
    dispatch(changeSearchParams({ invalidParams: updatedInvalidParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, invalidAcquisitionDateBefore]);

  const handleDateRangeSetClick = (value: number) => {
    if (searchParams.sourceType === 'survey' && searchParams.acquisitionDateAfter) {
      handleDateEndChange(addMonths(searchParams.acquisitionDateAfter, value));
    } else {
      const currentDate = new Date();
      handleDateEndChange(currentDate);
      handleDateStartChange(addMonths(currentDate, -value));
    }
  };

  return (
    <>
      <I18n>
        {({ i18n }) => (
          <div className={styles.dateRangeContainer}>
            <div className={styles.dateInput}>
              <DateInput
                locale={locale}
                value={searchParams.acquisitionDateAfter}
                onChange={handleDateStartChange}
                placeholder={i18n._(t`От`)}
                disabledDays={
                  isValid(searchParams.acquisitionDateBefore)
                    ? { after: searchParams.acquisitionDateBefore! }
                    : undefined
                }
                width="100%"
                format="dd.MM.yyyy"
                validateFunction={isValidDate}
                errorMessage={i18n._(t`Некорректная дата`)}
                allowEmpty={true}
                isError={setInvalidAcquisitionDateAfter}
              />
            </div>
            <div className={styles.dateInput}>
              <DateInput
                locale={locale}
                value={searchParams.acquisitionDateBefore}
                onChange={handleDateEndChange}
                placeholder={i18n._(t`До`)}
                pullRight={true}
                disabledDays={
                  isValid(searchParams.acquisitionDateAfter)
                    ? { before: searchParams.acquisitionDateAfter! }
                    : undefined
                }
                width="100%"
                format="dd.MM.yyyy"
                validateFunction={isValidDate}
                errorMessage={i18n._(t`Некорректная дата`)}
                isError={setInvalidAcquisitionDateBefore}
              />
            </div>
          </div>
        )}
      </I18n>
      <div className={styles.dateRangeSet}>
        <span onClick={() => handleDateRangeSetClick(1)}>
          <Trans>Месяц</Trans>
        </span>
        <span onClick={() => handleDateRangeSetClick(6)}>
          <Trans>Полгода</Trans>
        </span>
        <span onClick={() => handleDateRangeSetClick(12)}>
          <Trans>Год</Trans>
        </span>
      </div>
    </>
  );
};

export default DateRangeParam;
