import { t, Trans } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import { RootState } from 'ducks';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import xml2js from 'xml2js';

import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import DuplicateIcon from '@geobank/assets/src/icons/DuplicateIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import Loader from '@geobank/components/src/common/Loader/Loader';
import TextInput from '@geobank/components/src/forms/TextInput/TextInput';
import { getProfile } from 'ducks/authIAM';
import { notifyActions } from 'ducks/message';
import Tooltip from 'react-tooltip-lite';
import { iamConfig } from 'sagas/authIAM';
import { copyTextToClipboard } from 'utils/clipboard';

import { S3_DOC_URL, S3_TOKEN_DURATION_TIME, S3_URL } from 'api/realAPI';
import axios from 'axios';
import qs from 'qs';
import styles from './PersonDataTab.module.scss';

axios.defaults.headers = { 'Content-type': 'application/x-www-form-urlencoded' };

interface SearchResultProps {
  pushMessage: typeof notifyActions.push;
}

interface Credentials {
  [key: string]: string;
}

const PersonDataTab: React.FC<SearchResultProps & withI18nProps> = props => {
  const profile = useSelector(getProfile); // cast - fix!? bug
  const [showSTSCredentials, setShowSTSCredentials] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [STSCredentials, setSTSCredentials] = useState<Credentials>({
    AccessKeyId: '',
    SecretAccessKey: '',
    Expiration: '',
    SessionToken: '',
    ProductionDirectory: `cache-delivery/${profile.email}/`,
  });
  const getSTS = useCallback(async () => {
    const parser = new xml2js.Parser({ explicitArray: false });
    const token = localStorage.getItem('access_token');
    const data = {
      Action: 'AssumeRoleWithWebIdentity',
      DurationSeconds: S3_TOKEN_DURATION_TIME,
      WebIdentityToken: token,
      Version: '2011-06-15',
    };
    const response = await axios.post(S3_URL!, qs.stringify({ ...data }));
    const result = await parser.parseStringPromise(response.data);
    setSTSCredentials(prevState => ({
      ...prevState,
      ...result.AssumeRoleWithWebIdentityResponse.AssumeRoleWithWebIdentityResult.Credentials,
    }));
  }, []);

  useEffect(() => {
    if (isLoading) {
      getSTS()
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          props.pushMessage({
            message: props.i18n._(t`
            Ошибка при соединии с хранилищем S3
            `),
            color: 'alert',
            place: 'bc',
          });
        });
    }
  }, [getSTS, props, isLoading]);
  // if (profile === null) {
  //   return null;
  // }

  const groups = profile.groups.filter(group => group.startsWith('/Группы/'));
  const organizations = profile.groups.filter(group => group.startsWith('/Организации/'));

  const handleClick = () => {
    // Личный кабинет - https://auth.gptl.ru/auth/realms/etris/account
    // Смена пароля - https://auth.gptl.ru/auth/realms/etris/account/password
    window.open(`${iamConfig.url}/realms/${iamConfig.realm}/account`, '_blank');
    // win.focus();
  };

  const handleGetSTSClick = () => {
    setIsLoading(true);
    setShowSTSCredentials(true);
  };

  const handleCopyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const key: string = event.currentTarget.id;
    copyTextToClipboard(STSCredentials[key])
      .then(() => {
        props.pushMessage({
          message: props.i18n._(t`Cкопировано в буфер обмена`),
          color: 'success',
          place: 'bc',
        });
      })
      .catch(() => {
        props.pushMessage({
          message: props.i18n._(t`Ошибка при копировании в буфер обмена`),
          color: 'alert',
          place: 'bc',
        });
      });
  };

  const handleClose = () => {
    setShowSTSCredentials(false);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.dataContainer}>
          <div>
            <span className={styles.dataHeader}>
              <Trans>Личные данные</Trans>
            </span>
          </div>

          <div className={styles.formControl}>
            <div className={styles.labelContainer}>
              <span className={styles.label}>
                <Trans>Логин / E-mail</Trans>
              </span>
            </div>
            <div>
              <TextInput width="343px" name="username" value={profile.email || profile.username} />
            </div>
          </div>
          {/* <div className={styles.formControl}>
          <div className={styles.labelContainer}>
            <span className={styles.label}>
              <Trans>Имя</Trans>
            </span>
          </div>
          <div>
            <TextInput width="343px" value={profile.firstName} />
          </div>
        </div>
        <div className={styles.formControl}>
          <div className={styles.labelContainer}>
            <span className={styles.label}>
              <Trans>Фамилия</Trans>
            </span>
          </div>
          <div>
            <TextInput width="343px" value={profile.lastName} />
          </div>
        </div> */}
          <div className={styles.formControl}>
            <div className={styles.labelContainer}>
              <span className={styles.label}>
                <Trans>Имя и Фамилия</Trans>
              </span>
            </div>
            <div>
              <TextInput width="343px" value={`${profile.firstName} ${profile.lastName}`} />
            </div>
          </div>
          {groups.length > 0 && (
            <div className={styles.formControl}>
              <div className={styles.labelContainer}>
                <span className={styles.label}>
                  <Trans>Группа</Trans>
                </span>
              </div>
              <div>
                <TextInput width="343px" value={groups[0].replace('/Группы/', '')} />
              </div>
            </div>
          )}
          {organizations.length > 0 && (
            <div className={styles.formControl}>
              <div className={styles.labelContainer}>
                <span className={styles.label}>
                  <Trans>Организация</Trans>
                </span>
              </div>
              <div>
                <TextInput width="343px" value={organizations[0].replace('/Организации/', '')} />
              </div>
            </div>
          )}
          {/* <div className={styles.formControl}>
          <div className={styles.labelContainer}>
            <span className={styles.label}>
              <Trans>Идентификатор</Trans>
            </span>
          </div>
          <div>
            <TextInput width="343px" value={profile.id} />
          </div>
        </div> */}
        </div>

        <div className={styles.actionsContainer}>
          {/* <Button width='100%'>Изменить пароль</Button> */}
          <Button width="100%" /* disabled */ onClick={handleClick}>
            <Trans>Изменить данные</Trans>
          </Button>
        </div>
      </div>
      <div>
        <div className={styles.actionsContainer}>
          <Tooltip
            content={
              <div className={styles.link}>
                <a href={S3_DOC_URL} target="_blank" rel="noopener noreferrer">
                  <Trans>Работа с данными Фонда через протокол S3</Trans>
                </a>
              </div>
            }
            direction="right"
          >
            <Button
              style={{ marginTop: '8px', marginBottom: '8px', width: '100%' }}
              onClick={handleGetSTSClick}
            >
              <Trans>Получить реквизиты для доступа к хранилищу S3</Trans>
            </Button>
          </Tooltip>
        </div>
        {showSTSCredentials ? (
          <div className={styles.credentialsContainer}>
            <div className={styles.labelContainer}>
              <Button
                color={ButtonColor.TRANSPARENT}
                className={styles.closeButton}
                title={props.i18n._(t`Закрыть`)}
                onClick={handleClose}
              >
                <CrossIcon />
              </Button>
              <div className={styles.labelCredentials}>
                <span className={styles.label}>{`AWS_ACCESS_KEY_ID:`}</span>
                <Button
                  id="AccessKeyId"
                  color={ButtonColor.TRANSPARENT}
                  className={styles.itemHeadActionButton}
                  title={props.i18n._(t`Скопировать ключ в буфер обмена`)}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleCopyClick(event)}
                >
                  <DuplicateIcon fill="currentColor" />
                </Button>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <div>
                  <span className={styles.credentials}>{STSCredentials?.AccessKeyId}</span>
                </div>
              )}
            </div>
            <div className={styles.labelContainer}>
              <div className={styles.labelCredentials}>
                <span className={styles.label}>{`AWS_SECRET_ACCESS_KEY:`}</span>
                <Button
                  id="SecretAccessKey"
                  color={ButtonColor.TRANSPARENT}
                  className={styles.itemHeadActionButton}
                  title={props.i18n._(t`Скопировать секрет в буфер обмена`)}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleCopyClick(event)}
                >
                  <DuplicateIcon fill="currentColor" />
                </Button>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <div>
                  <span className={styles.credentials}>{STSCredentials?.SecretAccessKey}</span>
                </div>
              )}
            </div>
            <div className={styles.labelContainer}>
              <div className={styles.labelCredentials}>
                <span className={styles.label}>{`AWS_SESSION_TOKEN:`}</span>
                <Button
                  id="SessionToken"
                  color={ButtonColor.TRANSPARENT}
                  className={styles.itemHeadActionButton}
                  title={props.i18n._(t`Скопировать токен в буфер обмена`)}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleCopyClick(event)}
                >
                  <DuplicateIcon fill="currentColor" />
                </Button>
              </div>
              {isLoading ? (
                <Loader />
              ) : (
                <div className={styles.token}>
                  <span>{STSCredentials?.SessionToken}</span>
                </div>
              )}
            </div>
            <div className={styles.labelContainer}>
              <div className={styles.labelCredentials}>
                <span className={styles.label}>
                  {props.i18n._(t`Каталог продукции для скачивания:`)}
                </span>
                <Button
                  id="ProductionDirectory"
                  color={ButtonColor.TRANSPARENT}
                  className={styles.itemHeadActionButton}
                  title={props.i18n._(t`Скопировать адрес каталога в буфер обмена`)}
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleCopyClick(event)}
                >
                  <DuplicateIcon fill="currentColor" />
                </Button>
              </div>
              <div>
                <span className={styles.credentials}>{STSCredentials?.ProductionDirectory}</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default connect(
  (state: RootState) => ({}),
  {
    pushMessage: notifyActions.push,
  },
  null,
  { forwardRef: true }
)(withI18n()(PersonDataTab));
