import React from 'react';

import NewRangeInput from '@geobank/components/src/forms/NewRangeInput/NewRangeInput';

interface CloudinessFieldsetProps {
  cloudinessMax: number;
  onChange: (field: string, value: any) => void;
}

const CloudinessFieldset: React.FC<CloudinessFieldsetProps> = props => {
  const { cloudinessMax, onChange } = props;

  const handleChange = (values: number[]) => {
    onChange('cloudinessMax', values[0]);
  };

  return (
    <NewRangeInput
      min={0}
      max={100}
      step={1}
      values={[cloudinessMax ? cloudinessMax : 80]}
      trackColorStart={'#6AC3FA'}
      onChange={handleChange}
      minLabel={'0%'}
      maxLabel={'100%'}
    />
  );
};

export default CloudinessFieldset;
