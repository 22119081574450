// import { t } from '@lingui/macro';
import { i18nMark } from '@lingui/react';
import { USE_FAKE_API } from 'api/realAPI';
import { ActionType, getType } from 'deox'; // createActionCreator, createReducer
import cloneDeep from 'lodash/cloneDeep';
// import last from 'lodash/last';
import get from 'lodash/get';
// import remove from 'lodash/remove';
import { call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
// import requestFlow from './network'; // './request';
import { requestFlowNew } from './network';
import authRefresh from './networkIAM';

import { notifyActions } from 'ducks/message';
import {
  BandCombinationType,
  ImageMetadataDetail,
  SearchParams,
  SourceType,
} from 'ducks/types/metadataTypes';
import {
  clearMetadata,
  fetchImageMetadata,
  getActiveImageContours,
  getActiveImageFullResolutions,
  // toggleMetadataRouteContour,
  getActiveImagePreviews,
  // toggleMetadataRoutePreview,
  // toggleViewFullImage,
  getImageSourceType,
  getMetadata,
  removeMetadataRoute,
  searchImages /* , getSearchParams */,
  // ImageMetadata,
  selectMetadataRoute,
  toggleImageContour,
  toggleImageFullRes,
  toggleImagePreview,
} from '../ducks/metadata';

import GeoportalMap from 'components/map/Map/Map';

// import * as API from './api'
import API from 'api';
// import { prepareCatalogApiErrorMessage } from 'api/helperErrorMessage';
import {
  parseApiObjectV2,
  parseBbpMetadataObject,
  parseMetadataObject,
  parseStacMetadataObject,
  serializeMetadataSearchParams,
  serializeMetadataStacSearchParams,
} from 'components/utils/apiDataConverter';
import { genId } from 'components/utils/identifier';

import { getIsFree } from 'ducks/authIAM';
import { getLanguage } from 'ducks/locale';
import { captureSagaException } from 'modules/monitor/sentryHelper'; // captureSimpleException
import { Debugger } from 'utils/logging';
const debug = Debugger('MetadataSaga'); // TAG

const sensorToSource = {
  etris: [
    'AVR',
    'SVR',
    'SSR',
    'GSA',
    'MSUIKSRM',
    'MUL12U-R',
    // 'MSUTM101,MSUTM102',
    'MSU101,MSU102',
    'MSS',
    'GTNL1',
    'GTN',
    // КА БРИКС
    'IRS',
    'MUX',
    'MUXNAD',
    'PANMUX',
    'LISS3',
    'LISS4',
    'WFV',
    'WFI',
    'PMS',
    'BWD',
  ],
  bbp: ['MSUTM101,MSUTM102', 'MSUMR', 'OLITIRS', 'MSI'],
  stac: ['MSS', 'GTNL1', 'GTN'],
};

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

type SearchMetadataProvider = 'etris' | 'bbp' | 'stac';

export function* fetchEtrisResource(params: any) {
  try {
    const paramsForApi = serializeMetadataSearchParams(params);
    const data = yield call(authRefresh, requestFlowNew, API.searchImagesV2, paramsForApi);
    data.results = data.results.map((mdObject: any) => parseMetadataObject(mdObject));
    return data;
  } catch (error) {
    throw error;
  }
}

export function* fetchBbpResource(params: any) {
  try {
    const paramsForApi = serializeMetadataSearchParams(params);
    const data = yield call(authRefresh, requestFlowNew, API.searchBbpScenes, paramsForApi);
    data.results = data.results.map((mdObject: any) => parseBbpMetadataObject(mdObject));
    return data;
  } catch (error) {
    throw error;
  }
}

export function* fetchStacResource(params: any, catalog: string = 'esmdp2') {
  try {
    const paramsForApi = serializeMetadataStacSearchParams(params);
    const data = yield call(
      authRefresh,
      requestFlowNew,
      API.searchStacItems,
      paramsForApi,
      catalog
    );
    data.features = data.features.map((mdObject: any) => parseStacMetadataObject(mdObject));
    const results = { count: data.context.matched, results: data.features };
    return results;
  } catch (error) {
    throw error;
  }
}

export function* searchImagesWatch() {
  yield takeLatest(getType(searchImages.request), searchImagesFlow);
}

export function* searchImagesFlow(action: ActionType<typeof searchImages.request>) {
  const params: SearchParams = cloneDeep(action.payload);
  const imageSource: SourceType = yield select(getImageSourceType);
  imageSource === 'archive'
    ? yield call(searchFundImagesFlow, params)
    : yield call(searchEsmdpImagesFlow, params);
}

export function* searchFundImagesFlow(searchParams: SearchParams) {
  const messageId = genId();
  const isFreeUser = yield select(getIsFree);
  try {
    const etrisSensors: Partial<SearchParams> = {
      instrumentIdentifiers: [],
    };
    const bbpSensors: Partial<SearchParams> = {
      instrumentIdentifiers: [],
    };
    if (searchParams.instrumentIdentifiers && searchParams.instrumentIdentifiers.length > 0) {
      etrisSensors.instrumentIdentifiers = searchParams.instrumentIdentifiers.filter(sensor =>
        sensorToSource.etris.includes(sensor)
      );
      bbpSensors.instrumentIdentifiers = searchParams.instrumentIdentifiers.filter(sensor =>
        sensorToSource.bbp.includes(sensor)
      );
      if (!isFreeUser) {
        bbpSensors.instrumentIdentifiers = bbpSensors.instrumentIdentifiers.filter(
          sensor => !['MSUTM101,MSUTM102'].includes(sensor)
        );
        etrisSensors.instrumentIdentifiers = etrisSensors.instrumentIdentifiers?.filter(
          sensor =>
            ![
              'AVR',
              'MUL12U-R',
              'MSU101,MSU102',
              'PANMUX',
              'WFV',
              'WFI',
              'IRS',
              'PMS',
              'BWD',
              'LISS3',
              'LISS4',
              'MUXNAD',
            ].includes(sensor)
        );
      }
    }
    if (searchParams.acquisitionDateAfter instanceof Date) {
      searchParams.acquisitionDateAfter.setHours(0, 0, 0, 0);
    }
    if (searchParams.acquisitionDateBefore instanceof Date) {
      searchParams.acquisitionDateBefore.setHours(23, 59, 59, 0);
    }

    const append = get(searchParams, 'offset', 0) !== 0 || get(searchParams, 'ids', '') !== '';

    yield put(
      notifyActions.push({
        timeout: -1,
        id: messageId,
        message: i18nMark('Идет поиск снимков ...'),
        place: 'bc',
      })
    );

    const isETRISSearch: boolean =
      etrisSensors.instrumentIdentifiers!.length > 0 || searchParams.ids?.startsWith('ETRIS')!;

    const isBBPSearch: boolean =
      bbpSensors.instrumentIdentifiers!.length > 0 || searchParams.ids?.startsWith('BBP')!;

    const data = {
      count: 0,
      results: [] as any[],
    };

    const etrisResults: any = isETRISSearch
      ? yield call(fetchEtrisResource, { ...searchParams, ...etrisSensors })
      : null;

    const bbpResults: any = isBBPSearch
      ? yield call(fetchBbpResource, { ...searchParams, ...bbpSensors })
      : null;

    // TODO - отображать пользователю результаты, как только они приходят с сервера
    // @ts-ignore
    // const [etrisResults, bbpResults]: any = yield all([
    //   isETRISSearch ? call(fetchEtrisResource, { ...params, ...etrisSensors }) : null,
    //   isBBPSearch ? call(fetchBbpResource, { ...params, ...bbpSensors }) : null,
    // ]);

    data.count = get(bbpResults, 'count', 0) + get(etrisResults, 'count', 0);
    data.results = [...get(bbpResults, 'results', []), ...get(etrisResults, 'results', [])];

    yield put(notifyActions.remove(messageId));

    const map = GeoportalMap.getInstance();
    yield call([map, 'addMetadataResults'], data.results, append);
    debug.log(data.results);

    if (append === true) {
      const metadataRoutes = yield select(getMetadata); // getSearchResults
      data.results = [...metadataRoutes, ...data.results];
    }

    yield put(searchImages.success({ count: data.count, results: data.results }));
    const resultsIds: string[] = data.results.map(result => result.identifier);
    yield put(toggleImageContour.success(resultsIds, true));
    if (data.count === 0) {
      yield put(
        notifyActions.push({
          timeout: 4000,
          color: 'info',
          message: i18nMark('Cнимков не найдено!'),
          place: 'bc',
        })
      );
    }
  } catch (error) {
    yield call(searchErrorFlow, error, searchParams);
  } finally {
    yield put(notifyActions.remove(messageId));
  }
}

export function* searchEsmdpImagesFlow(searchParams: SearchParams) {
  const messageId = genId();
  try {
    const isFreeUser: boolean = yield select(getIsFree);
    const stacSensors: Partial<SearchParams> = {
      instrumentIdentifiers: [],
    };
    if (searchParams.instrumentIdentifiers) {
      stacSensors.instrumentIdentifiers = sensorToSource.stac.filter(sensor =>
        searchParams.instrumentIdentifiers?.includes(sensor)
      );

      if (!isFreeUser) {
        stacSensors.instrumentIdentifiers = stacSensors.instrumentIdentifiers.filter(
          sensor => sensor !== 'AVR'
        );
      }
      stacSensors.instrumentIdentifiers = stacSensors.instrumentIdentifiers.map(item =>
        item === 'MSUTM101,MSUTM102' ? 'KMSS' : item
      );
    }
    if (searchParams.acquisitionDateAfter instanceof Date) {
      searchParams.acquisitionDateAfter.setHours(0, 0, 0, 0);
    }
    if (searchParams.acquisitionDateBefore instanceof Date) {
      searchParams.acquisitionDateBefore.setHours(23, 59, 59, 0);
    }

    const append = get(searchParams, 'offset', 0) !== 0 || get(searchParams, 'ids', 0) !== 0;

    yield put(
      notifyActions.push({
        timeout: -1,
        id: messageId,
        message: i18nMark('Идет поиск снимков ...'),
        place: 'bc',
      })
    );

    const isDigitalEarthSearch: boolean =
      searchParams.sourceType === 'esmdp2' || searchParams.ids?.startsWith('APOI')!;

    const sortBy = [{ direction: 'desc', field: 'datetime' }];
    const data = {
      count: 0,
      results: [] as any[],
    };

    // TODO - отображать пользователю результаты, как только они приходят с сервера
    // @ts-ignore
    if (isDigitalEarthSearch) {
      const digitalEarthResults: any = yield call(fetchStacResource, {
        ...searchParams,
        ...stacSensors,
        sortBy: sortBy,
      });
      data.count = get(digitalEarthResults, 'count', 0);
      data.results = get(digitalEarthResults, 'results', []);
    } else {
      const stacResults: any = yield call(
        fetchStacResource,
        {
          ...searchParams,
          ...stacSensors,
          sortBy: sortBy,
        },
        'stac-archive'
      );
      data.count = get(stacResults, 'count', 0);
      data.results = [...get(stacResults, 'results', [])];
    }

    const count = data.count;
    let results = data.results;

    if (USE_FAKE_API === 'yes') {
      const delayValue = getRandomInt(100, 5000);
      debug.log('delayValue', delayValue);
      yield delay(delayValue);
    }

    const map = GeoportalMap.getInstance();
    yield call([map, 'addMetadataResults'], results, append); // false // resData.data
    debug.log(results);

    if (append === true) {
      const metadataRoutes = yield select(getMetadata); // getSearchResults
      results = [...metadataRoutes, ...results];
    }
    yield put(searchImages.success({ count, results }));
    const resultsIds: string[] = results.map(result => result.identifier);
    yield put(toggleImageContour.success(resultsIds, true));

    yield put(notifyActions.remove(messageId));

    if (count === 0) {
      yield put(
        notifyActions.push({
          timeout: 4000,
          color: 'info',
          message: i18nMark('Cнимков не найдено!'),
          place: 'bc',
        })
      );
    }
  } catch (error) {
    yield call(searchErrorFlow, error, searchParams);
  } finally {
    yield put(notifyActions.remove(messageId));
  }
}

export function* searchErrorFlow(error: any, action: any) {
  debug.log(error);
  const locale = yield select(getLanguage);
  yield put(searchImages.failure(error));

  if (error.isAxiosError) {
    const errorsParsed = parseApiObjectV2(error.response.data);
    const errorMessage = errorsParsed[locale];

    yield put(
      notifyActions.push({
        color: 'alert',
        message: errorMessage,
        place: 'bc',
      })
    );
  } else {
    yield put(
      notifyActions.push({
        color: 'alert',
        message: i18nMark('Ошибка при поиске снимков'),
        place: 'bc',
      })
    );
  }
  captureSagaException(error, action.type, action.payload);
}

export function* fetchImageMetadataWatch() {
  yield takeLatest(getType(fetchImageMetadata.request), fetchImageMetadataFlow);
}
export function* fetchImageMetadataFlow(action: ActionType<typeof fetchImageMetadata.request>) {
  try {
    const { callback, ...params } = action.payload;

    const paramsForApi = serializeMetadataSearchParams(params);
    const data = yield call(authRefresh, requestFlowNew, API.searchImagesV2, paramsForApi);
    data.results = data.results.map((mdObject: any) => parseMetadataObject(mdObject));
    // console.log(data);
    if (callback !== undefined) {
      callback(data);
    }
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    // console.error('Error', error);
  }
}

export function* selectMetadataRouteWatch() {
  // yield takeLatest(getType(selectMetadataRoute), selectMetadataRouteFlow);
  yield takeLatest(selectMetadataRoute, selectMetadataRouteFlow);
}
export function* selectMetadataRouteFlow(action: ActionType<typeof selectMetadataRoute>) {
  try {
    const { metadataId, isZoomNeed } = action.payload;
    const metadata: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveImagePreviews> = yield select(
      getActiveImagePreviews
    );
    const map = GeoportalMap.getInstance();
    // gpMap.selectMetadataFeature(metadataId);
    yield call([map, 'selectMetadataFeature'], metadataId, isZoomNeed);
    yield call([map, 'toggleImagePreview'], metadata, false);
    yield put(
      toggleImagePreview.success(
        activeImageIds.filter(item => item !== metadataId),
        false
      )
    );
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

export function* clearMetadataFeaturesWatch() {
  yield takeLatest(clearMetadata, clearMetadataFlow);
}

export function* clearMetadataFlow(action: ActionType<typeof clearMetadata>) {
  try {
    const { ids } = action.payload;
    const map = GeoportalMap.getInstance();
    yield call([map, 'clearMetadataFeatures'], ids);
  } catch (error) {
    captureSagaException(error, action.type);
    console.error('Error', error);
  }
}

export function* removeMetadataRouteWatch() {
  yield takeLatest(removeMetadataRoute, removeMetadataRouteFlow);
}

export function* removeMetadataRouteFlow(action: ActionType<typeof removeMetadataRoute>) {
  try {
    const { metadataId } = action.payload;

    const map = GeoportalMap.getInstance();
    yield call([map, 'removeMetadataFeature'], metadataId);
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

// export function* toggleMetadataRouteContourWatch() {
//   // yield takeLatest(getType(toggleMetadataRouteContour), toggleMetadataRouteContourFlow);
//   yield takeLatest(toggleMetadataRouteContour, toggleMetadataRouteContourFlow);
// }
// export function* toggleMetadataRouteContourFlow(
//   action: ActionType<typeof toggleMetadataRouteContour>
// ) {
//   try {
//     const { metadataId } = action.payload;
//     const activeViewFullImages = yield select(getActiveViewFullImages);
//     const activeImageContours = yield select(getActiveImageContours);
//     const isViewFullImageActive =
//       activeViewFullImages.indexOf(String(metadataId)) > -1 ? true : false;
//     const isImageContourActive =
//       activeImageContours.indexOf(String(metadataId)) > -1 ? true : false;

//     const map = GeoportalMap.getInstance();
//     yield call(
//       [map, 'toggleMetadataContour'],
//       metadataId,
//       isViewFullImageActive,
//       isImageContourActive
//     );
//   } catch (error) {
//     captureSagaException(error, action.type, action.payload);
//     console.error('Error', error);
//   }
// }
export function* toggleImageContourWatch() {
  yield takeLatest(getType(toggleImageContour.request), toggleImageContourFlow);
}
export function* toggleImageContourFlow(action: ActionType<typeof toggleImageContour.request>) {
  try {
    const { ids } = action.payload; // flag, isZoomNeed
    // const images: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveImageContours> = yield select(
      getActiveImageContours
    );

    const [_flag, inactived, actived] = getToggleFlagDetailed(activeImageIds, ids);
    // const idsToUpdate = flag === true ? inactived : actived;
    // inverting
    const flag = !_flag;
    const idsToUpdate = flag === true ? actived : inactived;
    // activeImageContours
    // const imagesToUpdate = images.filter(md => idsToUpdate.indexOf(String(md.id)) > -1);
    // console.log(flag, inactived, actived);

    const activeViewFullImages: ReturnType<typeof getActiveImageFullResolutions> = yield select(
      getActiveImageFullResolutions
    );
    const isImagesFullResActive = idsToUpdate.map(mdId => activeViewFullImages.indexOf(mdId) > -1);

    // const activeImageContours = yield select(getActiveImageContours);
    // const isViewFullImageActive =
    //   activeViewFullImages.indexOf(String(metadataId)) > -1 ? true : false;
    // const isImageContourActive =
    //   activeImageContours.indexOf(String(metadataId)) > -1 ? true : false;

    const map = GeoportalMap.getInstance();
    yield call([map, 'toggleImageContour'], idsToUpdate, isImagesFullResActive, flag);

    yield put(toggleImageContour.success(idsToUpdate, flag)); // (ids, flag)
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

// export function* toggleMetadataRoutePreviewWatch() {
//   // yield takeLatest(getType(toggleMetadataRoutePreview), toggleMetadataRoutePreviewFlow);
//   yield takeLatest(toggleMetadataRoutePreview, toggleMetadataRoutePreviewFlow);
// }
// export function* toggleMetadataRoutePreviewFlow(
//   action: ActionType<typeof toggleMetadataRoutePreview>
// ) {
//   try {
//     const { metadataId } = action.payload; // isZoomNeed
//     const metadataRoutes = yield select(getMetadata); // getSearchResults
//     // const previewsTemp = metadataRoutes.find((item: Metadata) => item.metadata_id === metadataId).previews;
//     const route = metadataRoutes.find((item: ImageMetadata) => item.id === metadataId);

//     const map = GeoportalMap.getInstance();
//     yield call([map, 'createImageFeature'], route); // previewsTemp
//   } catch (error) {
//     captureSagaException(error, action.type, action.payload);
//     console.error('Error', error);
//   }
// }

/* const getToggleFlag = (activeImageIds: any[], selectedIds: any[]) => {
  let state = null as any;
  for (let id of selectedIds) {
    let s = activeImageIds.find(activeId => activeId === id) !== undefined ? 'active' : 'inactive';
    // console.log('for loop ', i, s);
    if (state === null) {
      state = s;
    } else if (state !== s) {
      state = 'mixed';
      break;
    }
  }
  const flag = state === 'active' ? false : true;
  return flag;
}; */
const getToggleFlagDetailed = (activeImageIds: string[], selectedIds: string[]) => {
  const inactived: string[] = [];
  const actived: string[] = [];

  for (const id of selectedIds) {
    const activeId = activeImageIds.find(aId => aId === id);
    if (activeId !== undefined) {
      actived.push(activeId);
    } else {
      inactived.push(id);
    }
  }

  const flag = selectedIds.length === actived.length ? false : true;
  return [flag, inactived, actived] as [boolean, string[], string[]];
};

// export function* toggleMetadataRoutePreviewWatch() {
//   // yield takeLatest(getType(toggleMetadataRoutePreview), toggleMetadataRoutePreviewFlow);
//   yield takeLatest(toggleMetadataRoutePreview, toggleMetadataRoutePreviewFlow);
// }
// export function* toggleMetadataRoutePreviewFlow(
//   action: ActionType<typeof toggleMetadataRoutePreview>
// ) {
//   try {
//     const { metadataId } = action.payload; // isZoomNeed
//     const metadataRoutes = yield select(getMetadata); // getSearchResults
//     // const previewsTemp = metadataRoutes.find((item: Metadata) => item.metadata_id === metadataId).previews;
//     const route = metadataRoutes.find((item: ImageMetadata) => item.id === metadataId);

//     const map = GeoportalMap.getInstance();
//     yield call([map, 'createImageFeature'], route); // previewsTemp
//   } catch (error) {
//     captureSagaException(error, action.type, action.payload);
//     console.error('Error', error);
//   }
// }
export function* toggleImagePreviewWatch() {
  // yield takeLatest(getType(toggleImagePreview), toggleMetadataRoutePreviewFlow);
  yield takeLatest(getType(toggleImagePreview.request), toggleImagePreviewFlow);
}
export function* toggleImagePreviewFlow(action: ActionType<typeof toggleImagePreview.request>) {
  try {
    // ids - selected ids
    const { ids } = action.payload; // flag, isZoomNeed
    const images: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveImagePreviews> = yield select(
      getActiveImagePreviews
    );
    // const previewsTemp = metadataRoutes.find((item: Metadata) => item.metadata_id === metadataId).previews;
    // const route = metadataRoutes.find((item: ImageMetadata) => item.id === metadataId);

    // let state =
    //   activeImageIds.find(activeId => activeId === ids[0]) !== undefined ? 'active' : 'inactive';
    // // for (let id of ids) {
    // for (let i = 1; i < ids.length; i++) {
    //   let id = ids[i];
    //   let s =
    //     activeImageIds.find(activeId => activeId === id) !== undefined ? 'active' : 'inactive';
    //   // console.log('for loop ', i, s);
    //   if (state !== s) {
    //     state = 'mixed';
    //     break;
    //   }
    // }
    /* let state = null as any;
    for (let id of ids) {
      let s =
        activeImageIds.find(activeId => activeId === id) !== undefined ? 'active' : 'inactive';
      // console.log('for loop ', i, s);
      if (state === null) {
        state = s;
      } else if (state !== s) {
        state = 'mixed';
        break;
      }
    }
    // debugger;
    // console.log('end for loop ', state);
    const flag = state === 'active' ? false : true;
    const selectedImages = images.filter(md => ids.indexOf(String(md.id)) > -1); */

    // console.log(getToggleFlagDetailed(activeImageIds, ids));
    const [flag, inactived, actived] = getToggleFlagDetailed(activeImageIds, ids);
    const idsToUpdate = flag === true ? inactived : actived;
    const imagesToUpdate = images.filter(md => idsToUpdate.indexOf(md.identifier) > -1);
    // console.log(flag, inactived, actived);

    const map = GeoportalMap.getInstance();
    // yield call([map, 'createImageFeature'], route); // previewsTemp
    yield call([map, 'toggleImagePreview'], imagesToUpdate, flag);

    yield put(toggleImagePreview.success(ids, flag));
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

export function* toggleImageFullResWatch() {
  // yield takeLatest(getType(toggleViewFullImage), toggleViewFullImageFlow);
  yield takeLatest(getType(toggleImageFullRes.request), toggleImageFullResFlow);
}
export function* toggleImageFullResFlow(action: ActionType<typeof toggleImageFullRes.request>) {
  try {
    // ids - selected ids
    const ids = action.payload;
    const bandCombination = ids[0].split('.').slice(-1)[0] as BandCombinationType;
    const images: ReturnType<typeof getMetadata> = yield select(getMetadata); // getSearchResults
    const activeImageIds: ReturnType<typeof getActiveImageFullResolutions> = yield select(
      getActiveImageFullResolutions
    );
    const [_flag, inactived, actived] = getToggleFlagDetailed(activeImageIds, ids);
    let flag = _flag;
    const idsToUpdate = flag ? inactived : actived;
    let imagesToUpdate = images.filter(md => {
      const _md = md as ImageMetadataDetail;
      if (_md.coverage?.length === 0) {
        return false;
      }
      return idsToUpdate.indexOf(`${md.identifier}.${bandCombination}`) > -1;
    });

    // for (const image of imagesToUpdate) {
    //   (image as ImageMetadataDetail).coverage = (image as ImageMetadataDetail).coverage.filter(
    //     cov => (isMultispectral ? cov.isMultispectral : !cov.isMultispectral)
    //   );
    // }

    if (imagesToUpdate.length === 0) {
      imagesToUpdate = images.filter(md => actived.indexOf(`${md.identifier}`) > -1);
      flag = false;
    }
    const map = GeoportalMap.getInstance();
    // const layers = getLayersFromImageMetadata(imagesToUpdate as ImageMetadataDetail[]);
    yield call(
      [map, 'toggleImageFullRes'],
      imagesToUpdate as ImageMetadataDetail[],
      bandCombination,
      flag
    );

    // prettier-ignore
    yield put(toggleImageFullRes.success(imagesToUpdate.map(md => `${md.identifier}.${bandCombination}`), flag)); // (ids, flag)
    // }
  } catch (error) {
    captureSagaException(error, action.type, action.payload);
    console.error('Error', error);
  }
}

export default function* root() {
  yield fork(searchImagesWatch);
  yield fork(fetchImageMetadataWatch);
  yield fork(selectMetadataRouteWatch);
  yield fork(clearMetadataFeaturesWatch);
  yield fork(removeMetadataRouteWatch);
  yield fork(toggleImageContourWatch);
  yield fork(toggleImagePreviewWatch);
  yield fork(toggleImageFullResWatch);
}
