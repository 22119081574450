import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { t } from '@lingui/macro';
import { i18nMark, withI18n, withI18nProps } from '@lingui/react';
import * as turf from '@turf/turf';
import Tooltip from 'react-tooltip-lite';

import VectorLayer from 'libs/ol/layer/Vector';
import GeoJSON, { GeoJSONFeature } from 'ol/format/GeoJSON';
import Draw from 'ol/interaction/Draw';
import { Vector as VectorSource } from 'ol/source';

import { RootState } from 'ducks';
import { getGeofenceGeometry } from 'ducks/map';
import { notifyActions } from 'ducks/message';

import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import PolygonIcon from '@geobank/assets/src/icons/PolygonIcon';
import SquareIcon from '@geobank/assets/src/icons/SquareIcon';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
// import * as store from 'ducks';
import { genId } from 'components/utils/identifier';
import { isValidGeofenceIntersection } from 'components/utils/validation/checkIntersections';
import { Map as GeoportalMap } from '../Map';
import { selectStyle } from '../Map/featureStyles';
import { IFeatureType, polygonTypeFunctionMapping } from './DrawControl';

import styles from './DrawControl.module.scss';

const tooltipProps = {
  direction: 'right',
  hoverDelay: 800,
  padding: '8px',
  background: 'white',
  color: 'black',
};

interface DrawControlProps {
  isShow: boolean;
  geofence: any[];
  pushMessage: typeof notifyActions.push;
  onDrawEnd: (value: GeoJSONFeature) => void;
  onClear: () => void;
}

interface DrawControlState {
  isDrawActive: boolean;
  isValidCutContour: boolean;
}

export class DrawControlNext extends PureComponent<
  DrawControlProps & withI18nProps,
  DrawControlState
> {
  public state = {
    isDrawActive: false,
    isValidCutContour: true,
  };
  private source?: VectorSource;
  private vector?: VectorLayer;
  private draw?: Draw;

  private getPolygonType = (polygonType: string) => {
    return polygonTypeFunctionMapping[polygonType];
  };

  public handleDrawClick = (value: string) => {
    this.setState({ isDrawActive: true });
    const { polygonType, geometryFunction }: IFeatureType = this.getPolygonType(value);

    const map = GeoportalMap.getInstance();

    this.handleClear();

    if (polygonType) {
      this.draw = new Draw({
        // condition: geofenceLayer
        //   ? e => {
        //       const features = map.getOlMap().getFeaturesAtPixel(e.pixel, {
        //         layerFilter: layer => layer === geofenceLayer,
        //       });
        //       if (!(features && features.length > 0)) {
        //         this.props.pushMessage({
        //           timeout: 5000,
        //           message: i18nMark(
        //             'Район интереса должен находиться в пределах ограничительного района, заданного Оператором системы!'
        //           ),
        //           place: 'bc',
        //           color: 'alert',
        //         });
        //       }
        //       return features && features.length > 0;
        //     }
        //   : e => {
        //       return true;
        //     },
        // source: this.source,
        type: polygonType,
        geometryFunction: geometryFunction,
        stopClick: true,
      });
      this.draw.setProperties({ type: polygonType });
      // TODO - select feature `Polygon` on draw end!
      this.draw.on('drawend', event => {
        const feature = event.feature;
        feature.setId(genId()); // TODO - feature.setId(1001); // 'drawGeometry'
        feature.set('_type', 'region2');

        setTimeout(() => {
          map.getOlMap().removeInteraction(this.draw!);
          let geometryIntersection: boolean = true;
          const format = new GeoJSON();
          const geoJSON = format.writeFeatureObject(feature, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          });
          if (this.props.geofence.length > 0) {
            geometryIntersection = isValidGeofenceIntersection(
              geoJSON.geometry.type === 'Polygon' ? geoJSON.geometry.coordinates : [],
              this.props.geofence[0].geometry.coordinates
            );
          }
          if (geometryIntersection) {
            this.props.onDrawEnd(geoJSON);
            this.source?.addFeature(feature);
          } else {
            const imagePolygon = new GeoJSON().writeFeature(feature, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            });
            const geofenceGeometry = turf.getGeom(this.props.geofence[0]);
            const cutGeometry = turf.getGeom(JSON.parse(imagePolygon));
            const intersection = turf.intersect(cutGeometry, geofenceGeometry);
            if (intersection) {
              // this.source?.removeFeature(feature);
              if (intersection.geometry.type === 'MultiPolygon') {
                this.setState({ ...this.state, isValidCutContour: false });
                this.source?.removeFeature(feature);
                this.props.pushMessage({
                  timeout: 5000,
                  message: i18nMark(
                    'В качестве района интереса поддерживаются геометрии только типа "Polygon"!'
                  ),
                  place: 'bc',
                  color: 'alert',
                });
              } else {
                this.props.onDrawEnd(intersection);
                const intersectionFeature = new GeoJSON().readFeature(intersection, {
                  dataProjection: 'EPSG:4326',
                  featureProjection: 'EPSG:3857',
                });
                this.source?.addFeature(intersectionFeature);
              }
            } else {
              this.setState({ ...this.state, isValidCutContour: false });
              this.props.pushMessage({
                timeout: 5000,
                message: i18nMark(
                  'Контур обрезки выходит за пределы ограничительного района, заданного Оператором системы!'
                ),
                place: 'bc',
                color: 'alert',
              });
            }
          }
        }, 100);

        this.setState({ isDrawActive: false });
      });
      map.getOlMap().addInteraction(this.draw);
    }
  };

  public handleClear = () => {
    this.draw && this.draw.finishDrawing();
    this.draw?.setProperties({ type: '' });
    this.source && this.source.clear();

    const map = GeoportalMap.getInstance().getOlMap();
    if (this.draw && this.draw.getActive()) {
      map.removeInteraction(this.draw);
    }
    this.props.onClear();
  };

  public clearRegion = () => {
    this.source && this.source.clear();
  };

  public componentDidMount() {
    this.source = new VectorSource({ wrapX: false });

    const map = GeoportalMap.getInstance();
    const layer = map.ensureCurrentRegionLayer('regionLayerNext', 90); // TODO - layer name param!!!
    this.vector = layer;
    this.vector.setStyle(selectStyle);
    this.vector.setZIndex(90);
    layer.setSource(this.source);
    this.handleDrawClick('Box');
  }

  public componentDidUpdate() {
    const map = GeoportalMap.getInstance().getOlMap();
    if (!this.state.isValidCutContour) {
      this.draw?.abortDrawing();
      map.removeInteraction(this.draw!);
    }
  }

  public componentWillUnmount() {
    const map = GeoportalMap.getInstance().getOlMap();
    if (this.draw && this.draw.getActive()) {
      map.removeInteraction(this.draw);
    }
  }

  public render() {
    const { isShow, i18n } = this.props;

    if (!isShow) {
      return null;
    }

    return (
      <div className={styles.root}>
        {/* TODO - disable actions on isDrawActive */}
        <Tooltip
          {...tooltipProps}
          content={i18n._(t('map_draw.rectangle')`Нарисовать прямоугольник`)}
        >
          <Button
            className={styles.actionButton}
            color={
              this.draw?.get('type') === 'Circle' ? ButtonColor.LIGHT : ButtonColor.TRANSPARENT
            }
            onClick={this.handleDrawClick.bind(this, 'Box')}
          >
            <SquareIcon />
          </Button>
        </Tooltip>
        <Tooltip {...tooltipProps} content={i18n._(t('map_draw.polygon')`Нарисовать полигон`)}>
          <Button
            className={styles.actionButton}
            color={
              this.draw?.get('type') === 'Polygon' ? ButtonColor.LIGHT : ButtonColor.TRANSPARENT
            }
            onClick={this.handleDrawClick.bind(this, 'Polygon')}
          >
            <PolygonIcon />
          </Button>
        </Tooltip>
        <Tooltip {...tooltipProps} content={i18n._(t('map_draw.clear')`Очистить результат`)}>
          <Button
            className={styles.actionButton}
            color={ButtonColor.TRANSPARENT}
            onClick={this.handleClear}
          >
            <CrossIcon fill="grey" />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

export default withI18n({ withRef: true })(
  connect(
    (state: RootState) => ({ geofence: getGeofenceGeometry(state) }),
    { pushMessage: notifyActions.push },
    null,
    { forwardRef: true }
  )(DrawControlNext)
);
